/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, {
  FC, useMemo, useState, useRef, useEffect,
} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ComponentResizeDetector } from '@/ui/ComponentResizeDetector';
import { Paper } from '@material-ui/core';
import { Popup } from '@/ui';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { IconButton } from '@/ui/IconButton';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { omit } from 'lodash';
import { PropsI } from './types';
import useStyles from './styles';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/map')(Highcharts);

require('highcharts/modules/sunburst')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

window.Highcharts = Highcharts;

const ChartUniversal: FC<PropsI> = ({
  title, subtitle, xAxisCategories, labelsItems, series, isShowLabel = true,
  yAxisCategories, type, labelFormat, chartAdditinal, paperAdditional, className,
  isMultipleColumns = false, isEnabledLegend = true, titleSize = '18px',
  plotOptionsAdditinal, isStacking = false, isYAxisCategories = true,
  loading = false, isShowTop10,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [isOpen, setOpen] = useState(false);

  const column = isMultipleColumns
    ? { borderWidth: 0, pointPadding: 0.2 }
    : {
      grouping: false, shadow: false, borderWidth: 0, stacking: 'normal',
    };

  const count = useMemo(() => (xAxisCategories?.length && xAxisCategories[0].categories
    ? (xAxisCategories[0].categories?.length || 0) : 0), [xAxisCategories]);

  const chartOptions = useMemo(() => ({
    exporting: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: theme.palette.text.primary,
        opacity: 0.64,
        fontWeight: 'bold',
      },
      itemHoverStyle: {
        color: theme.palette.text.primary,
        opacity: 0.84,
        fontWeight: 'bold',
      },
      labelFormat,
      maxHeight: 110,
      enabled: isEnabledLegend,
    },
    title: {
      text: title,
      style: { color: theme.palette.text.primary, opacity: 0.84, fontSize: titleSize },
    },
    subtitle: {
      text: subtitle,
    },
    xAxis: isShowLabel ? xAxisCategories : { labels: { enabled: false }, ...(xAxisCategories as any) },
    labels: {
      items: labelsItems,
    },
    series,
    chart: {
      backgroundColor: 'transparent',
      type,
      events: {
        click: (e: any) => {
          if (!e.target.classList.contains('highcharts-reset-zoom')) {
            setOpen(!isOpen);
            if (isShowTop10) {
              isShowTop10(!isOpen);
            }
          }
        },
      },
      ...chartAdditinal,
    },
    colors: [
      '#7cb5ec', '#26588d', '#90ed7d', '#f7a35c',
      '#8085e9', '#f15c80', '#e4d354', '#2b908f',
      '#f45b5b', '#91e8e1'],
    plotOptions: {
      pie: {
        dataLabels: {
          style: {
            color: 'white', opacity: 0.84,
          },
          ...plotOptionsAdditinal,
        },
        showInLegend: true,
      },
      ...(isStacking && { area: { stacking: 'normal' } }),
      ...(count > 1 && { area: { pointPlacement: 'on' } }),
      ...(count > 1 && { spline: { pointPlacement: 'on' } }),
      column,
    },
    yAxis: isYAxisCategories ? yAxisCategories : [{ title: { text: null } }],
  }), [
    theme.palette.text.primary, labelFormat, isEnabledLegend, title, titleSize,
    subtitle, isShowLabel, xAxisCategories, labelsItems, series, type, chartAdditinal,
    plotOptionsAdditinal, isStacking, count, column, isYAxisCategories, yAxisCategories, isShowTop10, isOpen]);

  const chartOptionsDialog = useMemo(() => ({
    ...(!['pie', 'sunburst'].includes(type) && {
      mapNavigation: {
        enabled: false,
        enableButtons: false,
        buttons: {
          zoomIn: {
            alignTo: 'spacingBox',
            theme: { fill: '#bdbdc0' },
            padding: 0,
          },
          zoomOut: {
            alignTo: 'spacingBox',
            y: 18,
            theme: { fill: '#bdbdc0' },
            padding: 0,
          },
        },
      },
    }),
    exporting: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: theme.palette.text.primary,
        opacity: 0.64,
        fontWeight: 'bold',
      },
      itemHoverStyle: {
        color: theme.palette.text.primary,
        opacity: 0.84,
        fontWeight: 'bold',
      },
      labelFormat,
      maxHeight: 110,
    },
    title: {
      text: title,
      style: { color: theme.palette.text.primary, opacity: 0.84 },
    },
    subtitle: {
      text: subtitle,
    },
    xAxis: xAxisCategories,
    labels: {
      items: labelsItems,
    },
    series: (series as any).map((item: any) => omit(item, 'size')),
    chart: {
      backgroundColor: 'transparent',
      type,
      height: '80%',
    },
    plotOptions: {
      pie: {
        dataLabels: {
          style: {
            color: 'white', opacity: 0.84,
          },
          ...plotOptionsAdditinal,
        },
        showInLegend: true,
      },
      ...(isStacking && { area: { stacking: 'normal' } }),
      ...(count > 1 && { area: { pointPlacement: 'on' } }),
      ...(count > 1 && { spline: { pointPlacement: 'on' } }),
      column,
    },
    yAxis: yAxisCategories,
  }), [
    theme.palette.text.primary, labelFormat, title, subtitle, xAxisCategories,
    labelsItems, series, type, plotOptionsAdditinal, count, column, yAxisCategories, isStacking,
  ]);

  const [widthDetector, setWidthDetector] = useState(0);
  const refGrid = useRef<HTMLDivElement>(null);

  useEffect(() => {
    Highcharts.charts.forEach((chart: Highcharts.Chart | undefined) => {
      if (chart) {
        chart.reflow();
      }
    });
  }, [widthDetector, count]);

  return (
    <Paper className={clsx(classes.containerChart, className)} style={paperAdditional}>
      {!loading && (
        <>
          <IconButton
            size="small"
            aria-label="Fullscreen"
            onClick={() => {
              setOpen(!isOpen);
              if (isShowTop10) {
                isShowTop10(!isOpen);
              }
            }}
            className={classes.iconFullScreen}
          >
            <FullscreenIcon fontSize="small" />
          </IconButton>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
          />
          <ComponentResizeDetector
            widthDetector={widthDetector}
            setWidthDetector={setWidthDetector}
            refGrid={refGrid}
          />
          <Popup
            isOpen={isOpen}
            onClose={() => {
              setOpen(false);
              if (isShowTop10) {
                isShowTop10(!isOpen);
              }
            }}
            className={classes.dialog}
          >
            <section className={classes.containerChartDialog}>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptionsDialog}
              />
            </section>
          </Popup>
        </>
      )}
    </Paper>
  );
};

export default ChartUniversal;
