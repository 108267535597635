import { Filter } from '@devexpress/dx-react-grid';
import { TypeDateE } from '@/utils/date/types';

export enum GroupChartE {
    ADVERTISER = 'advertiser',
    FEED_ID = 'feedId',
    GLO_SRC_ID = 'gloSrcId',
    GLO_GROUP_ID = 'gloGroup',
    GLO_CAMP = 'gloCamp',
    GLO_EMPLOYER = 'employer',
    DATE = 'date',
    DATE_ADV = 'dateAdv',
    DATE_PUB = 'datePub',
    EVENTS = 'type',
}

export enum GroupByChartE {
    ADVERTISER = 'advertiser',
    FEED_ID = 'feedId',
    GLO_GROUP_ID = 'gloGroup',
    GLO_CAMP = 'gloCamp',
}

export enum DataChartE {
    REV = 'rev',
    PARTNER_CPC = 'partnerCpc',
    COST = 'cost',
    ROI = 'roi',
    ROI_PERCENT = 'roiPercent',
    JOBS = 'jobs',
    CR = 'cr',
    FRAUD_SCORE = 'ipqsFraudScoreLimit',
    FRAUD_AVG = 'ipqsFraudScoreAvg',
    TOTAL_STATS = 'totalStats',
    LAST_IMPORT_REPORT = 'lastImportReport',
    LAST_EXPORT_REPORT = 'lastExportReport',
    YESTERDAY_REPORT = 'yesterdayReport',
    NOTIFICATIONS = 'notifications',
    MTD_REPORT = 'mtdReport',
    RECONCILIATION = 'reconciliation',
    RANGE_CPC = 'rangeCpc',
    MATRIX = 'matrix',
    BLEND_CPC = 'blendCpc',
    BLEND_CR = 'blendCr',
    TOP_FIVE_GAINERS = 'topFiveGainers',
    TOP_FIVE_GAINERS_REV = 'partnerCpcTopFiveGainers',
    TOP_FIVE_GAINERS_ROI = 'roiTopFiveGainers',
    TOP_FIVE_GAINERS_PERFORMANCE = 'blendCpcTopFiveGainers',
    TOP_FIVE_GAINERS_FRAUD = 'ipqsFraudScoreLimitTopFiveGainers',
}

export enum UserDataChartE {
    EVENTS = 'events',
    EMPLOYER = 'employer',
    RANGE_CPC = 'rangeCpcUser',
    UNPAID = 'unpaid',
    RECONCILIATION = 'reconciliation'
}

export enum TypeChartE {
    PIE = 'pie',
    LINE = 'line',
    NUMBER = 'number',
    COLUMN = 'column',
    STATISTICS = 'statistics',
    TABLE = 'table',
}

export enum TypeFormatE {
    PERCENT = 'percent',
    NUMBER = 'number',
    CURRENCY = 'currency'
}

export interface IProps {
    index: number;
    handleRemove: (index: number) => void;
    handleEdit: (index: number, fields: IFields) => void;
    handlePin?: (index: number) => void;
    isShowPin?: boolean;
    filterByDate: Filter[];
    fieldsChart: IFields;
    keys?: string[];
    filter?: Filter[];
    edit?: (id: number | undefined, type: string) => void;
    loading?: boolean;
}

export interface IFields {
    id: string;
    type: TypeChartE | '';
    data: DataChartE | UserDataChartE;
    group?: GroupChartE;
    groupBy?: GroupByChartE;
    date?: TypeDateE | '';
    comparison?: string;
    typeFormat: TypeFormatE | '';
    filter?: string;
    filterAdv?: string;
    filterPub?: string;
}
