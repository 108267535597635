/* eslint-disable semi */
/* eslint-disable arrow-body-style */
/* eslint-disable block-spacing */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import {
  mdiBriefcase as JobsIcon,
  mdiCog as SettingsIcon,
  mdiAccountMultiple as TalentIcon,
  mdiWan as NetworkIcon,
  // mdiSwapVertical as ImportExportIcon,
  mdiPoll as PerformanceIcon,
  mdiPower as LogoutIcon,
  mdiViewDashboard as DashboardIcon,
  mdiOfficeBuilding as OfficeIcon,
  mdiWebhook as TrafficIcon,
  mdiApi as ApiIcon,
  mdiSend as PublisherIcon,
  mdiGoogleAds as AdvertisersIcon,
  mdiVectorCircle as AffiliateIcon,
  mdiShoppingOutline as MarketplaceIcon,
  // mdiAutorenew as AutoRenew,
  // mdiBeekeeper as JobsApi,
} from '@mdi/js';
import WebIcon from '@material-ui/icons/Web';
import { ReactComponent as QuestionIcon } from '@/theme/icons/question.svg';
import { ReactComponent as Trade } from '@/theme/icons/trade.svg';
import {
  SETTINGS_PROFILE,
  SETTINGS_SETTINGS,
  JOBS_FEEDS,
  JOBS_JOBS,
  PERFORMANCE_OVERVIEW,
  PERFORMANCE_RECONCILIATION,
  PERFORMANCE_RECONCILIATION_ADVERTISERS,
  PERFORMANCE_RECONCILIATION_PUBLISHERS,
  PERFORMANCE_TRENDS,
  PERFORMANCE_MATRIX,
  PERFORMANCE_LOGS,
  PERFORMANCE_REPORTS,
  JOBS_LIVE_JOBS,
  JOBS_CAMPAIGNS,
  JOBS_MAP,
  JOBS_IMPORT_DUPLICATES,
  JOBS_IMPORT_BLOCKED,
  TRADE_MATRIX,
  SUPPORT_TICKET,
  SUPPORT_CONTACT,
  TALENT,
  TALENT_API,
  TALENT_AUDIENCES,
  TALENT_SOURCER,
  TALENT_LTV,
  NETWORK_GLOBAL,
  NETWORK_ADVERTISERS,
  NETWORK_PUBLISHERS,
  SITES_NEW,
  SITES_MANAGE,
  DASHBOARD,
  PERFORMANCE_ALERTS,
  SETTINGS_ALERTS,
  TRADE_LOGS,
  TRAFFIC_INTENT,
  TRAFFIC_GEO_FENCE,
  TRAFFIC_VOLUME,
  TRAFFIC_CRO,
  TRAFFIC_PROTECTION_RECOUP,
  TRAFFIC_CPAC_CONVERT,
  TRAFFIC_ALL,
  API_PUB_SETTINGS_API,
  ADV_IMPORT,
  ADV_FEEDS_API,
  ADV_LIVE_JOBS,
  ADV_ADVERTISERS,
  ADV_RECONCILIATION_ADVERTISERS,
  ADV_BILLING,
  ADV_LOGS,
  PUB_EXPORT,
  PUB_SETTINGS_API,
  PUB_PUBLISHERS,
  PUB_RECONCILIATION_PUBLISHERS,
  PUB_BILLING,
  PUB_LOGS,
  PUB_API_KEY,
  WEBSITES,
  AFFILIATE_WIDGET,
  AFFILIATE_WEBSITES,
  SETTINGS_SMART_CONTRACTS,
  AUTOMATIONS,
  MARKETPLACE_BUY,
  MARKETPLACE_SELL,
  JOBS_USER_CAMPAIGNS, JOBS_PUB_FEEDS,
} from '@/constants/routes';
import {
  ADMIN, TRADE, ADV, PUB,
} from '@/constants/role';
import IconMenu from './IconMenu/IconMenu';
import { IMenu } from './types';

const itemPath = {
  dashboard: [DASHBOARD],
  performance: [
    PERFORMANCE_OVERVIEW,
    PERFORMANCE_REPORTS,
    PERFORMANCE_LOGS,
    PERFORMANCE_TRENDS,
    PERFORMANCE_RECONCILIATION,
    PERFORMANCE_RECONCILIATION_ADVERTISERS,
    PERFORMANCE_RECONCILIATION_PUBLISHERS,
    PERFORMANCE_ALERTS,
  ],
  traffic: [
    TRAFFIC_INTENT,
    TRAFFIC_GEO_FENCE,
    TRAFFIC_VOLUME,
    TRAFFIC_CRO,
    TRAFFIC_PROTECTION_RECOUP,
    TRAFFIC_CPAC_CONVERT,
    TRAFFIC_ALL,
  ],
  trade: [
    TRADE_MATRIX,
    TRADE_LOGS,
    AUTOMATIONS,
  ],
  jobs: [
    JOBS_FEEDS,
    JOBS_JOBS,
    JOBS_LIVE_JOBS,
    JOBS_MAP,
    JOBS_IMPORT_DUPLICATES,
    JOBS_IMPORT_BLOCKED,
  ],
  advertisers: [
    ADV_IMPORT,
    ADV_FEEDS_API,
    ADV_LIVE_JOBS,
    ADV_ADVERTISERS,
    ADV_RECONCILIATION_ADVERTISERS,
    ADV_BILLING,
    ADV_LOGS,
  ],
  publishers: [
    PUB_EXPORT,
    PUB_SETTINGS_API,
    PUB_PUBLISHERS,
    PUB_RECONCILIATION_PUBLISHERS,
    PUB_BILLING,
    PUB_LOGS,
  ],
  network: [NETWORK_GLOBAL, NETWORK_ADVERTISERS, NETWORK_PUBLISHERS],
  talent: [TALENT, TALENT_API, TALENT_AUDIENCES, TALENT_SOURCER, TALENT_LTV],
  support: [SUPPORT_TICKET, SUPPORT_CONTACT],
  settings: [SETTINGS_PROFILE, SETTINGS_SETTINGS, SETTINGS_ALERTS],
  api: [API_PUB_SETTINGS_API],
  websites: [SITES_NEW, SITES_MANAGE],
  logout: [],
  apikey: [PUB_API_KEY],
  affiliate: [AFFILIATE_WIDGET, AFFILIATE_WEBSITES],
  campaigns: [JOBS_CAMPAIGNS],
  marketplace: [MARKETPLACE_BUY, MARKETPLACE_SELL],
};

const getIcons = (path: string, open: boolean) => ({
  dashboard: <IconMenu Icon={DashboardIcon} itemPath={itemPath.dashboard} currentPath={path} state={open} />,
  performance: <IconMenu Icon={PerformanceIcon} itemPath={itemPath.performance} currentPath={path} state={open} />,
  traffic: <IconMenu Icon={TrafficIcon} itemPath={itemPath.traffic} currentPath={path} state={open} />,
  trade: <IconMenu Icon={Trade} itemPath={itemPath.trade} currentPath={path} state={open} />,
  jobs: <IconMenu Icon={JobsIcon} itemPath={itemPath.jobs} currentPath={path} state={open} />,
  advertisers: <IconMenu Icon={AdvertisersIcon} itemPath={itemPath.advertisers} currentPath={path} state={open} />,
  publishers: <IconMenu Icon={PublisherIcon} itemPath={itemPath.publishers} currentPath={path} state={open} />,
  network: <IconMenu Icon={NetworkIcon} itemPath={itemPath.network} currentPath={path} state={open} />,
  talent: <IconMenu Icon={TalentIcon} itemPath={itemPath.talent} currentPath={path} state={open} />,
  support: <IconMenu Icon={QuestionIcon} itemPath={itemPath.support} currentPath={path} state={open} />,
  settings: <IconMenu Icon={SettingsIcon} itemPath={itemPath.settings} currentPath={path} state={open} />,
  api: <IconMenu Icon={ApiIcon} itemPath={itemPath.api} currentPath={path} state={open} />,
  websites: <IconMenu Icon={WebIcon} itemPath={itemPath.websites} currentPath={path} state={open} />,
  logout: <IconMenu Icon={LogoutIcon} itemPath={itemPath.logout} currentPath={path} state={open} />,
  campaigns: <IconMenu Icon={OfficeIcon} itemPath={itemPath.campaigns} currentPath={path} state={open} />,
  affiliate: <IconMenu Icon={AffiliateIcon} itemPath={itemPath.affiliate} currentPath={path} state={open} />,
  marketplace: <IconMenu Icon={MarketplaceIcon} itemPath={itemPath.marketplace} currentPath={path} state={open} />,
});

const getMenuItems = (
  open: boolean,
  path: string,
  handle?: {
    route: (route: string) => () => void;
    signOut: () => Promise<void>;
  },
) => {
  const { route = (route: string) => { return () => {}}, signOut = () => {} } = handle || {};
  const icons = getIcons(path, open);
  return {
    dashboard: {
      key: DASHBOARD,
      name: 'Dashboard',
      Icon: icons.dashboard,
      onClick: route(DASHBOARD),
    },
    performanceAdmin: {
      key: 'performance',
      name: 'Performance',
      Icon: icons.performance,
      onClick: route(PERFORMANCE_OVERVIEW),
      items: [{
        key: PERFORMANCE_OVERVIEW, name: 'Analytics', onClick: route(PERFORMANCE_OVERVIEW), depth: 6.25,
      }, {
        key: PERFORMANCE_REPORTS, name: 'Reports', onClick: route(PERFORMANCE_REPORTS), depth: 6.25,
      }, {
        key: PERFORMANCE_MATRIX, name: 'Trade Matrix', onClick: route(PERFORMANCE_MATRIX), depth: 6.25,
      }, {
        key: PERFORMANCE_RECONCILIATION,
        name: 'Reconciliation',
        onClick: () => {},
        depth: 6.25,
        items: [{
          key: PERFORMANCE_RECONCILIATION_ADVERTISERS, name: 'Advertisers', depth: 7.5, onClick: route(PERFORMANCE_RECONCILIATION_ADVERTISERS),
        }, {
          key: PERFORMANCE_RECONCILIATION_PUBLISHERS, name: 'Publishers', depth: 7.5, onClick: route(PERFORMANCE_RECONCILIATION_PUBLISHERS),
        }],
      }, {
        key: PERFORMANCE_LOGS, name: 'Click Logs', onClick: route(PERFORMANCE_LOGS), depth: 6.25,
      },
      ],
    },
    performanceUser: {
      key: 'performance',
      name: 'Performance',
      Icon: icons.performance,
      onClick: route(PERFORMANCE_TRENDS),
      items: [{
        key: PERFORMANCE_TRENDS, name: 'Analytics', onClick: route(PERFORMANCE_TRENDS), depth: 6.25,
      }, {
        key: PERFORMANCE_REPORTS, name: 'Reports', onClick: route(PERFORMANCE_REPORTS), depth: 6.25,
      }, {
        key: PERFORMANCE_LOGS, name: 'Logs', onClick: route(PERFORMANCE_LOGS), depth: 6.25,
      }, {
        key: PERFORMANCE_RECONCILIATION, name: 'Reconciliation', onClick: route(PERFORMANCE_RECONCILIATION), depth: 6.25,
      }],
    },
    trade: {
      key: 'trade',
      name: 'Trade',
      Icon: icons.trade,
      onClick: route(TRADE_MATRIX),
      items: [{
        key: TRADE_MATRIX, name: 'Matrix', onClick: route(TRADE_MATRIX), depth: 6.25,
      },
      {
        key: TRADE_LOGS, name: 'History', onClick: route(TRADE_LOGS), depth: 6.25,
      }, {
        key: AUTOMATIONS, name: 'Auto', onClick: route(AUTOMATIONS), depth: 6.25, inactive: true,
      }],
    },
    jobsAdmin: {
      key: 'campaigns',
      name: 'Campaigns',
      Icon: icons.jobs,
      onClick: route(JOBS_CAMPAIGNS),
      items: [
        {
          key: JOBS_LIVE_JOBS, name: 'Live Jobs', onClick: route(JOBS_LIVE_JOBS), depth: 6.25,
        },
        {
          key: JOBS_CAMPAIGNS, name: 'Campaigns', onClick: route(JOBS_CAMPAIGNS), depth: 6.25,
        }, {
          key: JOBS_MAP, name: 'Map', onClick: route(JOBS_MAP), depth: 6.25,
        }, {
          key: JOBS_IMPORT_DUPLICATES, name: 'Duplicates', onClick: route(JOBS_IMPORT_DUPLICATES), depth: 6.25,
        }, {
          key: JOBS_IMPORT_BLOCKED, name: 'Blocked', onClick: route(JOBS_IMPORT_BLOCKED), depth: 6.25,
        }],
    },
    affiliate: {
      key: 'affiliate',
      name: 'Affiliate',
      Icon: icons.affiliate,
      onClick: () => {},
      items: [{
        key: PUB_API_KEY, name: 'API', onClick: route(PUB_API_KEY), depth: 6.25,
      }, {
        key: AFFILIATE_WIDGET, name: 'Widget', onClick: route(AFFILIATE_WIDGET), depth: 6.25,
      }, {
        key: AFFILIATE_WEBSITES, name: 'Website', onClick: route(AFFILIATE_WEBSITES), depth: 6.25,
      }],
    },
    jobsUserAdv: {
      key: 'jobs',
      name: 'Jobs',
      Icon: icons.jobs,
      onClick: () => {},
      items: [{
        key: JOBS_FEEDS, name: 'Feeds', onClick: route(JOBS_FEEDS), depth: 6.25,
      }, {
        key: JOBS_JOBS, name: 'Jobs', onClick: route(JOBS_JOBS), depth: 6.25,
      }, {
        key: JOBS_USER_CAMPAIGNS, name: 'Campaigns', onClick: route(JOBS_USER_CAMPAIGNS), depth: 6.25,
      }],
    },
    jobsUserPub: {
      key: 'jobs',
      name: 'Jobs',
      Icon: icons.jobs,
      onClick: route(JOBS_PUB_FEEDS),
      items: [{
        key: JOBS_PUB_FEEDS, name: 'Feeds', onClick: route(JOBS_PUB_FEEDS), depth: 6.25,
      }],
    },
    advertisers: {
      key: 'advertisers-ssp',
      name: 'Advertisers (SSP)',
      Icon: icons.advertisers,
      onClick: () => {},
      items: [{
        key: ADV_ADVERTISERS, name: 'Accounts', onClick: route(ADV_ADVERTISERS), depth: 6.25,
      }, {
        key: ADV_IMPORT, name: 'Feeds (XML)', onClick: route(ADV_IMPORT), depth: 6.25,
      }, {
        key: ADV_LIVE_JOBS, name: 'Preview Feeds', onClick: route(ADV_LIVE_JOBS), depth: 6.25,
      }, {
        key: ADV_RECONCILIATION_ADVERTISERS, name: 'Reconciliation', onClick: route(ADV_RECONCILIATION_ADVERTISERS), depth: 6.25,
      }, {
        key: ADV_BILLING, name: 'Billing', onClick: route(ADV_BILLING), depth: 6.25,
      }, {
        key: ADV_LOGS, name: 'History', onClick: route(ADV_LOGS), depth: 6.25,
      }],
    },
    publishers: {
      key: 'publishers-dsp',
      name: 'Publishers (DSP)',
      Icon: icons.publishers,
      onClick: () => {},
      items: [{
        key: PUB_PUBLISHERS, name: 'Accounts', onClick: route(PUB_PUBLISHERS), depth: 6.25,
      }, {
        key: PUB_EXPORT, name: 'Feeds (XML)', onClick: route(PUB_EXPORT), depth: 6.25,
      }, {
        key: PUB_SETTINGS_API, name: 'API (Real-Time)', onClick: route(PUB_SETTINGS_API), depth: 6.25,
      }, {
        key: PUB_RECONCILIATION_PUBLISHERS, name: 'Reconciliation', onClick: route(PUB_RECONCILIATION_PUBLISHERS), depth: 6.25,
      }, {
        key: PUB_BILLING, name: 'Billing', onClick: route(PUB_BILLING), depth: 6.25,
      }, {
        key: PUB_LOGS, name: 'History', onClick: route(PUB_LOGS), depth: 6.25,
      }],
    },
    traffic: {
      key: 'traffic',
      name: 'Traffic Quality',
      Icon: icons.traffic,
      onClick: route(TRAFFIC_ALL),
      items: [{
        key: TRAFFIC_ALL, name: 'All', onClick: route(TRAFFIC_ALL), depth: 6.25,
      }, {
        key: TRAFFIC_INTENT, name: 'Intent', onClick: route(TRAFFIC_INTENT), depth: 6.25,
      }, {
        key: TRAFFIC_CRO, name: 'CRO', onClick: route(TRAFFIC_CRO), depth: 6.25,
      }, {
        key: TRAFFIC_CPAC_CONVERT, name: 'CPAC Convert', onClick: route(TRAFFIC_CPAC_CONVERT), depth: 6.25,
      }, {
        key: TRAFFIC_VOLUME, name: 'Volume Protection', onClick: route(TRAFFIC_VOLUME), depth: 6.25,
      }, {
        key: TRAFFIC_PROTECTION_RECOUP, name: 'Redirect', onClick: route(TRAFFIC_PROTECTION_RECOUP), depth: 6.25,
      }, {
        key: TRAFFIC_GEO_FENCE, name: 'Geo Fence', onClick: route(TRAFFIC_GEO_FENCE), depth: 6.25,
      },
      ],
    },
    network: {
      key: 'network',
      name: 'Network',
      Icon: icons.network,
      onClick: () => {},
      inactive: true,
      items: [{
        key: NETWORK_ADVERTISERS, name: 'Advertisers', onClick: route(NETWORK_ADVERTISERS), depth: 6.25, inactive: true,
      }, {
        key: NETWORK_PUBLISHERS, name: 'Publishers', onClick: route(NETWORK_PUBLISHERS), depth: 6.25, inactive: true,
      }],
    },
    talent: {
      key: 'talent',
      name: 'Talent',
      Icon: icons.talent,
      onClick: () => {},
      inactive: true,
      items: [{
        key: TALENT, name: 'Talent', onClick: route(TALENT), depth: 6.25, inactive: true,
      }],
    },
    supportAdmin: {
      key: 'support',
      name: 'Support',
      Icon: icons.support,
      onClick: () => {},
      items: [{
        key: SUPPORT_TICKET, name: 'Submit Ticket', onClick: route(SUPPORT_TICKET), depth: 6.25,
      }],
    },
    supportUser: {
      key: 'support',
      name: 'Support',
      Icon: icons.support,
      onClick: () => {},
      items: [{
        key: SUPPORT_CONTACT, name: 'Contact us', onClick: route(SUPPORT_CONTACT), depth: 6.25,
      }],
    },
    settingsAdmin: {
      key: 'settings',
      name: 'Settings',
      Icon: icons.settings,
      onClick: () => {},
      items: [{
        key: SETTINGS_PROFILE, name: 'Profile', onClick: route(SETTINGS_PROFILE), depth: 6.25,
      }, {
        key: SETTINGS_SETTINGS, name: 'Settings', onClick: route(SETTINGS_SETTINGS), depth: 6.25,
      }, {
        key: SETTINGS_ALERTS, name: 'Logs&Alerts', onClick: route(SETTINGS_ALERTS), depth: 6.25,
      }],
    },
    api: {
      key: 'api',
      name: 'Api',
      Icon: icons.api,
      onClick: route(API_PUB_SETTINGS_API),
    },
    settingsUser: {
      key: 'settings',
      name: 'Settings',
      Icon: icons.settings,
      onClick: () => {},
      items: [{
        key: SETTINGS_PROFILE, name: 'Profile', onClick: route(SETTINGS_PROFILE), depth: 6.25,
      }, {
        key: SETTINGS_SETTINGS, name: 'Settings', onClick: route(SETTINGS_SETTINGS), depth: 6.25,
      }],
    },
    settingsUserPub: {
      key: 'settings',
      name: 'Settings',
      Icon: icons.settings,
      onClick: () => {},
      items: [{
        key: SETTINGS_PROFILE, name: 'Profile', onClick: route(SETTINGS_PROFILE), depth: 6.25,
      }, {
        key: SETTINGS_SMART_CONTRACTS, name: 'Payout Terms', onClick: route(SETTINGS_SMART_CONTRACTS), depth: 6.25,
      }],
    },
    websites: {
      key: 'websites',
      name: 'Websites',
      Icon: icons.websites,
      onClick: () => {},
      inactive: true,
      items: [{
        key: WEBSITES, name: 'Websites', onClick: route(WEBSITES), depth: 6.25, inactive: true,
      }],
    },
    marketplace: {
      key: 'marketplace',
      name: 'Marketplace',
      Icon: icons.marketplace,
      onClick: () => {},
      inactive: true,
      items: [{
        key: MARKETPLACE_BUY, name: 'Buy', onClick: route(MARKETPLACE_BUY), depth: 6.25, inactive: true,
      }, {
        key: MARKETPLACE_SELL, name: 'Sell', onClick: route(MARKETPLACE_SELL), depth: 6.25, inactive: true,
      }],
    },
    logout: {
      key: 'logout',
      name: 'Logout',
      Icon: icons.logout,
      onClick: () => signOut(),
    },
  };
};

const config = (
  role: string, open?: boolean, path?: string,
  handle?: {
    route: (route: string) => () => void;
    signOut: () => Promise<void>;
  },
  userEmail?: string | undefined,
): IMenu[] => {
  const items = getMenuItems(!!open, path || '', handle);
  const hiddenByUser = ['partners@urbanlocal.io'];
  if (role === ADMIN || role === TRADE) {
    return [
      items.dashboard,
      items.performanceAdmin,
      items.trade,
      items.jobsAdmin,
      items.advertisers,
      items.publishers,
      items.traffic,
      items.network,
      items.api,
      items.talent,
      items.websites,
      items.settingsAdmin,
      items.logout,
    ];
  }
  if (role === ADV) {
    return [
      items.performanceUser,
      items.jobsUserAdv,
      items.marketplace,
      items.settingsUser,
      items.supportUser,
      items.logout,
    ];
  }
  if (role === PUB) {
    if (userEmail && hiddenByUser.includes(userEmail)) {
      return [
        items.performanceUser,
        items.jobsUserPub,
        items.supportUser,
        items.settingsUserPub,
        items.logout,
      ];
    }
    return [
      items.performanceUser,
      items.jobsUserPub,
      items.affiliate,
      items.marketplace,
      items.settingsUserPub,
      items.supportUser,
      items.logout,
    ];
  }
  return [];
};

export default config;
